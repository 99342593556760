import {Fragment, useMemo, useState} from 'react';
import {
	IconAdjustmentsHorizontal,
	IconArrowDown,
	IconArrowsSort,
	IconArrowUp,
	IconChevronLeft,
	IconChevronRight,
	IconChevronsLeft,
	IconChevronsRight,
	IconColumnsOff,
	IconEyeOff,
	IconFilter,
	IconTable,
} from '@tabler/icons-react';
import {
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
} from '@tanstack/react-table';
import assign from 'lodash.assign';
import omit from 'lodash.omit';
import {twMerge} from 'tailwind-merge';

import type {
	Column,
	ColumnDef,
	ColumnFiltersState,
	OnChangeFn,
	PaginationState,
	RowData,
	SortingState,
	Table as ReactTable,
	VisibilityState,
} from '@tanstack/react-table';
import type {ComponentPropsWithoutRef, ReactNode} from 'react';
import Button from '../button';
import DropdownMenu from '../dropdown-menu';
import Empty from '../empty';
import IconButton from '../icon-button';
import Indicator from '../indicator';
import LoadingSpinner from '../loading-spinner';
import Overlay from '../overlay';
import Popover from '../popover';
import SearchField from '../search-field';
import Select from '../select';
import Table from '../table';
import TextButton from '../text-button';
import type {Icon} from '../../types';

export type DataTableColumn<TData, TValue = unknown> = ColumnDef<TData, TValue>;

export type DataTableColumnHeaderProps<TData, TValue = unknown> = ComponentPropsWithoutRef<'div'> & {
	column: Column<TData, TValue>;
	title: string;
};

const DataTableColumnHeader = <TData, TValue>({
	column,
	title,
	className,
}: DataTableColumnHeaderProps<TData, TValue>) => {
	if (!column.getCanSort()) {
		return <div className={twMerge(className)}>{title}</div>;
	}

	return (
		<div className={twMerge('-ml-2 flex items-center gap-0.5', className)}>
			<DropdownMenu>
				<DropdownMenu.Trigger asChild>
					<Button
						iconRight={
							column.getIsSorted() === 'desc'
								? IconArrowDown
								: column.getIsSorted() === 'asc'
									? IconArrowUp
									: IconArrowsSort
						}
						intent="ghost"
						size="sm"
					>
						{title}
					</Button>
				</DropdownMenu.Trigger>
				<DropdownMenu.Content align="start" className="w-20 min-w-fit">
					<DropdownMenu.RadioGroup
						onValueChange={value => column.toggleSorting(value === 'desc')}
						value={`${column.getIsSorted()}`}
					>
						<DropdownMenu.RadioItem icon={IconArrowUp} value="asc">
							Asc
						</DropdownMenu.RadioItem>
						<DropdownMenu.RadioItem icon={IconArrowDown} value="desc">
							Desc
						</DropdownMenu.RadioItem>
					</DropdownMenu.RadioGroup>
					{column.getCanHide() && (
						<>
							<DropdownMenu.Separator />
							<DropdownMenu.Item icon={IconEyeOff} onSelect={() => column.toggleVisibility(false)}>
								Hide
							</DropdownMenu.Item>
						</>
					)}
				</DropdownMenu.Content>
			</DropdownMenu>
			{column.getCanFilter() && (
				<Popover>
					<div className="relative">
						<Popover.Trigger asChild>
							<IconButton aria-label="Open filter menu" icon={IconFilter} intent="ghost" size="sm" />
						</Popover.Trigger>
						{column.getIsFiltered() && (
							<Indicator className="absolute right-1.5 top-1.5" color="danger" size="xs" />
						)}
					</div>

					<Popover.Content align="start" className="flex items-center">
						<SearchField
							className="mx-1 w-full"
							clearable
							label="Filter"
							onChange={value => column.setFilterValue(value)}
							placeholder={`Filter ${column.id}...`}
							size="sm"
							subtle
							value={(column.getFilterValue() as string) || ''}
						/>
					</Popover.Content>
				</Popover>
			)}
		</div>
	);
};

export type DataTablePaginationProps<TData> = {
	table: ReactTable<TData>;
	loading?: boolean;
};

export const DataTablePagination = <TData extends RowData>({
	table,
	loading,
}: DataTablePaginationProps<TData>) => (
	<div className="flex w-full flex-wrap items-center justify-between">
		<div className="flex grow items-center justify-between gap-2">
			<div className="flex w-1/3 items-center justify-start gap-1">
				<Select
					onValueChange={value => {
						table.setPageSize(Number(value));
					}}
					value={table.getState().pagination.pageSize.toString()}
				>
					<Select.Trigger
						aria-label="Select page size"
						className="shrink-0"
						disabled={loading}
						placeholder={table.getState().pagination.pageSize.toString()}
						size="sm"
						subtle
					/>
					<Select.Content side="top">
						{[10, 20, 30, 40, 50].map(pageSize => (
							<Select.Item key={pageSize} value={`${pageSize}`}>
								{pageSize}
							</Select.Item>
						))}
					</Select.Content>
				</Select>
				<p className="hidden truncate text-sm font-medium text-mauve11 xs:block">per page</p>
			</div>
			{table.getCoreRowModel().rows.length > 0 && (
				<div className="flex w-1/3 items-center justify-center text-sm font-medium text-mauve11">
					{loading ? (
						<LoadingSpinner />
					) : (
						<>
							{Intl.NumberFormat().format(table.getState().pagination.pageIndex + 1)} of{' '}
							{Intl.NumberFormat().format(table.getPageCount())}
						</>
					)}
				</div>
			)}
			<div className="flex w-1/3 items-center justify-end gap-1">
				<IconButton
					aria-label="Go to first page"
					className="hidden lg:flex"
					disabled={!table.getCanPreviousPage() || loading}
					icon={IconChevronsLeft}
					intent="ghost"
					onPress={() => table.setPageIndex(0)}
					size="sm"
				/>
				<IconButton
					aria-label="Go to previous page"
					disabled={!table.getCanPreviousPage() || loading}
					icon={IconChevronLeft}
					intent="ghost"
					onPress={() => table.previousPage()}
					size="sm"
				/>
				<IconButton
					aria-label="Go to next page"
					disabled={!table.getCanNextPage() || loading}
					icon={IconChevronRight}
					intent="ghost"
					onPress={() => table.nextPage()}
					size="sm"
				/>
				<IconButton
					aria-label="Go to last page"
					className="hidden lg:flex"
					disabled={!table.getCanNextPage() || loading}
					icon={IconChevronsRight}
					intent="ghost"
					onPress={() => table.setPageIndex(table.getPageCount() - 1)}
					size="sm"
				/>
			</div>
		</div>
	</div>
);

export type DataTableViewOptionsProps<TData> = {
	table: ReactTable<TData>;
};

export const DataTableViewOptions = <TData extends RowData>({table}: DataTableViewOptionsProps<TData>) => (
	<DropdownMenu>
		<DropdownMenu.Trigger asChild>
			<Button className="ml-auto" iconLeft={IconAdjustmentsHorizontal} intent="ghost" size="sm">
				View
			</Button>
		</DropdownMenu.Trigger>
		<DropdownMenu.Content align="end" className="z-50">
			<DropdownMenu.Label>Toggle Columns</DropdownMenu.Label>
			<DropdownMenu.Separator />
			{table
				.getAllColumns()
				.filter(column => column.accessorFn !== undefined && column.getCanHide())
				.map(column => (
					<DropdownMenu.CheckboxItem
						checked={column.getIsVisible()}
						className="capitalize"
						key={column.id}
						onCheckedChange={value => column.toggleVisibility(!!value)}
					>
						{column.id}
					</DropdownMenu.CheckboxItem>
				))}
		</DropdownMenu.Content>
	</DropdownMenu>
);

export type DataTableProps<TData, TValue = unknown, TColumns extends ColumnDef<TData, TValue>[] = []> = {
	columns: TColumns;
	data: TData[];
	loading?: boolean;
	disablePagination?: boolean;
	disableViewOptions?: boolean;
	globalHeader?: ReactNode;
	className?: string;
	rowActions?: (row: TData) => ReactNode;
	disableHeader?: boolean;
	emptyText?: string;
	emptyTitle?: string;
	emptyIcon?: Icon;
} & (
	| {
			manualPagination: true;
			rowCount?: number;
			pagination: PaginationState;
			onPaginationChange: OnChangeFn<PaginationState>;
	  }
	| {
			manualPagination?: false;
			rowCount?: never;
			pagination?: never;
			onPaginationChange?: never;
	  }
);

const DataTable = assign(
	<TData, TValue = unknown, TColumns extends ColumnDef<TData, TValue>[] = []>({
		columns,
		data,
		loading,
		disablePagination,
		disableViewOptions,
		globalHeader,
		disableHeader,
		className,
		rowActions,
		manualPagination,
		emptyTitle = 'No data',
		emptyText = 'We need more data to show you something here',
		emptyIcon = IconTable,
		rowCount,
		pagination,
		onPaginationChange,
	}: DataTableProps<TData, TValue, TColumns>) => {
		const [sorting, setSorting] = useState<SortingState>([]);
		const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
		const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
		const [rowSelection, setRowSelection] = useState({});

		const paginationProps = useMemo(
			() => (manualPagination ? {rowCount, manualPagination, onPaginationChange} : {}),
			[manualPagination, onPaginationChange, rowCount],
		);

		const table = useReactTable({
			data,
			columns,
			onSortingChange: setSorting,
			onColumnFiltersChange: setColumnFilters,
			getCoreRowModel: getCoreRowModel(),
			getPaginationRowModel: getPaginationRowModel(),
			getSortedRowModel: getSortedRowModel(),
			getFilteredRowModel: getFilteredRowModel(),
			onColumnVisibilityChange: setColumnVisibility,
			onRowSelectionChange: setRowSelection,
			...paginationProps,
			state: omit(
				{sorting, columnFilters, columnVisibility, rowSelection, pagination},
				manualPagination ? [] : ['pagination'],
			),
		});

		return (
			<div className={twMerge('flex flex-col', className)}>
				{(!disableViewOptions || globalHeader) && (
					<div className="mb-2 flex flex-row-reverse items-center justify-between">
						{!disableViewOptions && <DataTableViewOptions table={table} />}
						{globalHeader}
					</div>
				)}
				{table.getIsSomeColumnsVisible() ? (
					<Table>
						{!disableHeader && (
							<Table.Header>
								{table.getHeaderGroups().map(headerGroup => (
									<Table.Row className="group" key={headerGroup.id}>
										{headerGroup.headers.map((header, index) => (
											<Fragment key={header.id}>
												<Table.Head>
													{header.isPlaceholder
														? null
														: flexRender(header.column.columnDef.header, header.getContext())}
												</Table.Head>
												{headerGroup.headers.length - 1 === index && rowActions && (
													<Table.Head
														className={twMerge(
															'sticky right-0 bg-mauve2 text-center transition-colors group-hover:bg-mauve4',
															'before:absolute before:left-0 before:top-0 before:h-full before:w-[1px] before:bg-mauve6',
														)}
														key="actions"
													>
														Actions
													</Table.Head>
												)}
											</Fragment>
										))}
									</Table.Row>
								))}
							</Table.Header>
						)}
						<Table.Body>
							{table.getRowModel().rows.length > 0 ? (
								table.getRowModel().rows.map(row => (
									<Fragment key={row.id}>
										<Table.Row className="group" data-state={row.getIsSelected() && 'selected'}>
											{row.getVisibleCells().map(cell => (
												<Table.Cell className="whitespace-nowrap" key={cell.id}>
													{flexRender(cell.column.columnDef.cell, cell.getContext())}
												</Table.Cell>
											))}
											{rowActions && (
												<Table.Cell
													className={twMerge(
														'sticky right-0 bg-mauve2 transition-colors group-hover:bg-mauve4 [&>*]:mx-auto',
														'before:absolute before:left-0 before:top-0 before:h-full before:w-[1px] before:bg-mauve6',
														"after:absolute after:left-0 after:top-[-1px] after:h-[1px] after:w-full after:bg-mauve6 after:content-['']",
													)}
													key="actions"
												>
													{rowActions(row.original)}
												</Table.Cell>
											)}
										</Table.Row>
									</Fragment>
								))
							) : (
								<Table.Row>
									<Table.Cell
										className="pointer-events-none relative row-span-full min-h-24 text-center"
										colSpan={columns.length}
									>
										{loading ? (
											<Overlay loading />
										) : (
											<Empty icon={emptyIcon} subtitle={emptyText} title={emptyTitle} />
										)}
									</Table.Cell>
								</Table.Row>
							)}
						</Table.Body>
					</Table>
				) : (
					<Empty
						icon={IconColumnsOff}
						subtitle="Please enable at least one column"
						title="No columns visible"
					>
						<TextButton color="primary" onPress={() => table.toggleAllColumnsVisible(true)} size="sm">
							Show all columns
						</TextButton>
					</Empty>
				)}
				{!disablePagination && (
					<div className="flex shrink-0 items-center justify-end gap-2 px-2 py-4">
						<DataTablePagination loading={loading} table={table} />
					</div>
				)}
			</div>
		);
	},
	{ColumnHeader: DataTableColumnHeader},
);

export default DataTable;
