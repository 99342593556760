import {forwardRef, useContext, useImperativeHandle, useRef} from 'react';
import {useSearchFieldState} from 'react-stately';
import {useSearchField} from '@react-aria/searchfield';
import {IconSearch, IconX} from '@tabler/icons-react';
import omit from 'lodash.omit';
import {twMerge} from 'tailwind-merge';

import type {AriaSearchFieldProps} from '@react-aria/searchfield';
import type {ReactNode} from 'react';
import {FormControlContext} from '../form-control';
import IconButton from '../icon-button';
import LoadingSpinner from '../loading-spinner';
import {textFieldInputStyles, textFieldStyles} from '../text-field/styles';
import type {TextFieldVariantProps} from '../text-field/styles';

export type SearchFieldProps = Omit<AriaSearchFieldProps, 'isDisabled' | 'isReadOnly' | 'isRequired'> &
	Omit<TextFieldVariantProps, 'textarea' | 'invalid'> & {
		className?: string;
		required?: boolean;
		clearable?: boolean;
		loading?: boolean;
		suffix?: ReactNode;
		prefix?: ReactNode;
	};

const SearchField = forwardRef<HTMLInputElement, SearchFieldProps>(
	(
		{
			size,
			subtle,
			hidden,
			disabled,
			readonly,
			required,
			value,
			prefix,
			suffix,
			loading,
			clearable,
			className,
			...props
		},
		forwardedRef,
	) => {
		const formControlProps = useContext(FormControlContext);
		const ref = useRef<HTMLInputElement>(null);
		const state = useSearchFieldState({
			...props,
			value,
			isDisabled: !!disabled,
			isReadOnly: !!readonly,
			isRequired: required,
		});
		const {inputProps, clearButtonProps} = useSearchField(
			{
				...props,
				isDisabled: !!disabled,
				isReadOnly: !!readonly,
				isRequired: required,
				value,
				'aria-labelledby': formControlProps.id,
				id: `${formControlProps.id}-field`,
			},
			state,
			ref,
		);

		useImperativeHandle(forwardedRef, () => ref.current as HTMLInputElement);

		return (
			<div
				className={twMerge(
					textFieldStyles({
						size,
						subtle,
						hidden,
						invalid: formControlProps.invalid,
						disabled,
						readonly,
						textarea: false,
					}),
					className,
				)}
			>
				<IconSearch className="h-4 w-4 shrink-0" />
				{prefix && <div className="flex shrink-0 flex-nowrap items-center">{prefix}</div>}
				<input {...inputProps} className={textFieldInputStyles()} ref={ref} />
				{loading && <LoadingSpinner size="sm" />}
				{clearable && !disabled && !readonly && !!value && (
					<IconButton
						{...omit(clearButtonProps, ['preventFocusOnPress', 'isDisabled'])}
						as="div"
						circular
						icon={IconX}
						size="xs"
					/>
				)}
				{suffix && <div className="flex shrink-0 flex-nowrap items-center">{suffix}</div>}
			</div>
		);
	},
);

SearchField.displayName = 'SearchField';

export default SearchField;
